.layout-content {
  margin-left: 200px;
}

.custom-sider {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  transition: width 0.3s;
}

.custom-sider .logo {
  height: 64px;
  margin: 16px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.custom-sider .logo .icon-taxi {
  margin-right: 8px;
}

.custom-sider .ant-menu {
  background: #001529;
}

.tableSubCols.heading {
  font-size: large;
  font-weight: bolder;
}
.tableSubCols.heading-1 {
  font-size: medium;
  font-weight: bolder;
}
.tableSubCols.heading-2 {
  font-size: small;
  font-weight: bolder;
}
.tableSubCols.details {
  font-size: smaller;
}

@media (max-width: 1000px) {
  .layout-content {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .custom-sider {
    position: fixed;
  }
}
