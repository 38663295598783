/* utility */
.article-container {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.l-heading {
  font-size: 3rem;
}
/* sidebar */
/* side menu */
body {
  position: relative;
}

.sidebar {
  display: flex !important;
  flex-direction: column;
  justify-content: start !important;
  align-items: start !important;
  background: #f4f4f4;
  transition: all 0.53s ease;
  position: absolute;
  top: 80px;
  left: 0;
  height: 100vh;
  width: 0%;
  z-index: 3;
  overflow: hidden;
  /* padding-top: 20px; */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 10;
}
.sidebar.open {
  width: 300px;
  right: 0;
  overflow: hidden;
}
.sidebar li {
  margin: 10px 0 !important;
  width: 100%;
}
.sidebar li a {
  text-align: justify;
  padding: 10px !important;
}

/* navigation */
#sidebar-container {
  display: none;
}
#main .grid-container {
  grid-template-columns: 1fr;
}
#main-nav {
  display: none !important;
  background: red;
}

.sidebar {
  display: block !important;
}
.nav-list li {
  display: none !important;
}
#log-in-mobo {
  display: block !important;
  /* justify-self: flex-end; */
}
#info-mobo-btn {
  display: block !important;

  /* justify-self: flex-end; */
}
/* info nav */
.logo {
  margin-left: 30px;
}
#info-nav {
  position: fixed;
  /* top: 0; */
  height: 80px;
  z-index: 12;
  width: 100%;
}
#info-nav .container {
  padding-top: 0;
}
.btn-bars {
  display: block !important;
  position: fixed;
  top: 15px;
  left: 20px;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 5px;
  border: none;
}

.mobo-nav {
  display: flex !important;
  justify-content: end !important;
}
.mobo-nav #log-in-mobo a {
  padding: 0.4rem 1.3rem !important;
}
#info-mobo-btn a {
  font-size: 2.1rem;
  cursor: pointer;
}
/* app info */
#app .app-container {
  gap: 2rem;
}

#app .app-container img {
  /* max-height: 400px; */
  width: auto;
  /* object-fit: cover; */
}

/* footer */
#main-footer .footer-container {
  grid-template-columns: repeat(2, 1fr);
  padding-top: 5px;
}

#main-footer .footer-container > *:last-child {
  grid-column: 1 / span 2;
}
@media (max-width: 627px) {
  .l-heading {
    font-size: 2rem;
  }
  .article-container {
    grid-template-columns: 1fr;
  }
  /* app info */
  #app .app-container {
    grid-template-columns: 1fr;
  }

  /* footer */
  #main-footer .footer-container {
    grid-template-columns: repeat(1, 1fr);
  }

  #main-footer .footer-container > *:last-child {
    grid-column: 1;
  }
  #contact .page-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem;
    gap: 1.5rem;
  }
  #contact .page-container > *:first-child {
    grid-row: 1;
  }
}
