.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%;
}

.ant-spin {
  color: #1890ff; /* Customize spinner color if needed */
}
