:root {
  --background-color: #f7f7f7;
  --color-primary: #0c1cfc;
  --color-secondary: hwb(240 16% 77%);
  --light-color: #ffff;
  /* --secondary-color: #29293a; */
  --max-width: 1100px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Gotham SSm", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", Arial, sans-serif;

  line-height: 1.5;
  font-size: 16px;
  background: var(--background-color);
  /* height: 1000000px; */
}

a {
  color: var(--color-secondary);
  text-decoration: none;
  font-weight: 600;
}
ul {
  list-style: none;
}
p {
  margin: 0.5rem 0;
  line-height: 1.7;
}
img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.55rem;
  line-height: 1.3;
  /* color: var(--color-primary); */
}

/* utility */
.container {
  max-width: var(--max-width);
  margin: auto;
  padding: 1rem 2rem;
  overflow: hidden;
}
.btn {
  cursor: pointer;
  display: inline-block;
  border: none;
  border-radius: 5px;
  color: var(--light-color);
  background: var(--color-primary);
  padding: 0.5rem 1.7rem;
  transition: all 0.4s ease-in-out;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column > * {
  margin-bottom: 20px;
}
.article-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.l-heading {
  font-size: 4rem;
  font-weight: 500;
}

.l-text {
  font-size: 20px;
}
.bg-dark {
  background: var(--color-secondary);
  color: #fff;
}
.text-primary {
  color: #fcad0c;
}
.py-2 {
  padding: 20px 0;
}
.py-3 {
  padding: 30px 0;
}
i {
  color: var(--color-primary);
}
.hidden {
  display: none;
}

.iti {
  width: 100%;
}

.section:first-child {
  padding-top: 100px;
  min-height: 70vh;
}

/* Hamburger */

#btn-nav-control {
  color: #333;
  background: #f4f4f4;
  font-size: 26px;
}

/* navigation */
#main-nav.top {
  background: transparent;
  color: #fff;
}
#main-nav {
  position: fixed !important;
  height: 80px;
  width: 100%;
  top: 0;
  z-index: 11;
  background: #f5f5f5;
  opacity: 0.9;
  transition: 0.5s;
}

#main-nav .container {
  display: grid;
  grid-template-columns: 9fr 2fr;
  align-items: start;
  justify-content: center;
}

#main-nav .nav-list {
  display: flex;
}
#main-nav .nav-list li {
  margin-left: 50px;
}
#main-nav .nav-list a {
  padding: 0.75rem;
  margin: 0 5px;
}
#main-nav .container #main-menu li a:hover,
#main-nav .container #main-menu li .current {
  color: var(--color-primary);
}

.btn-bars {
  display: none !important;
}
.sidebar {
  display: none !important;
}
.mobo-btn {
  display: none !important;
}

.error {
  color: red;
  display: none;
}

/* info nav */

#info-nav {
  /* height: 70px; */

  /* align-items: center; */

  /* z-index: 12; */
  background: var(--light-color);
}
#info-nav .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding-top: 90px;
  /* background: var(--light-color); */
}
.logo {
  width: 80px;
}
#info-nav .container ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
#info-nav .container ul li {
  margin-left: 40px;
}
#info-nav .container ul li a {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 5px;
}

#info-nav .container ul li a i {
  padding-right: 5px;
}
#info-nav .container ul li a:hover {
  color: #ccc;
}

/* showcase */
#showcase {
  color: var(--light-color);
  padding: 2rem;
  background: url("/public/images/showcase.jpg") no-repeat center center/cover;
  position: relative;
}
#showcase::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

#showcase .showcase-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
  z-index: 2;
  position: relative;
}

#showcase .showcase-content p {
  margin-top: 1rem;
  /* z-index: 2; */
}

#showcase .btn {
  transition: all 0.5s ease-in-out;
  padding: 0.6rem 3rem;
  font-size: 17px;
}

#showcase .btn:hover {
  color: var(--color-secondary);
  background: #f7f7f7;
}

/* about info */
#about-info {
  background: var(--light-color);
}

/* app */

#app .app-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

#app .app-container .flex-column {
  align-items: start;
}
#app .app-container img {
  display: block;
  margin: auto;
  max-height: 400px;
  max-width: 300px;
  /* object-fit: cover; */
}

/* advantage */

#advantage .article-container i {
  border: 2px solid #fcad0c;
  border-radius: 50%;
  padding: 1rem;
  /* padding: 1000px; */
  color: var(--light-color);
  /* height: 200px; */
  position: absolute;
  top: -30px;
}
#advantage .article-container .flex-column {
  border: 1px solid #444;
  padding: 1rem;
  position: relative;
}
#advantage .article-container .flex-column h1 {
  margin-top: 4rem;
  margin-bottom: 5px;
  font-size: 26px;
}

/* car type */
#car-type .container .article-container img {
  /* width: 400px; */
  /* height: 150px; */
  /* object-fit: cover; */
}

/* contact */

#contact .page-container {
  display: grid;
  grid-template-columns: 5fr 2fr;
  margin: 1rem;
  margin-top: 5rem;
  gap: 1.5rem;
}

#contact .page-container > *:first-child {
  grid-row: 1 / span 3;
  padding: 20px;
  background: var(--light-color);
}
#contact .social {
  background: var(--light-color);
  padding: 1rem;
}

.social ul {
  display: flex;
}
.social ul li {
  text-align: center;
}
.social ul a {
  padding: 5px;
  text-align: center;
}
#contact .form-container input,
textarea {
  display: block;
  padding: 23px 15px;
  margin: 15px 0;
  width: 100%;
  background-color: #f4f4f4;
  border: none;
  border-radius: 10px;
}

#contact .form-container textarea {
  height: 200px;
}
#contact .form-container button {
  padding: 15px;
}
#contact .form-container input:focus,
#contact .form-container textarea:focus {
  outline: none;
  border: var(--color-primary) 2px solid !important;
}

/* login */
.form .container {
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: start;
  /* overflow-y: scroll; */
}
#login-page .container {
  height: 70vh;
}
#login-form .iti input {
  width: 100%;
}
/*
#login-form .form-group input {
  height: 30px;
  width: 100%;
} */

/* Callback form */
#form-container {
  height: 80vh;
}
#form-container form {
  overflow-x: hidden;
  height: 75vh;
  padding-bottom: 40px;
}
.callback-form {
  width: 50%;
  padding: 20px 0;
  padding-bottom: 70px;
}

.callback-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.callback-form .form-group {
  margin-bottom: 15px;
  display: block;
}

.callback-form a {
  text-align: center;
}

.callback-form .form-group a,
.callback-form .form-group .btn {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.callback-form .form-group input {
  width: 98%;
  padding: 4px;
  height: 37px;
  border: #f5f5f5 1px solid;
}
.callback-form .form-group select {
  width: 90%;
  height: 30px;
}
.callback-form input:focus {
  outline: none;
  border: 1px solid var(--color-primary);
}

.callback-form .btn {
  font-size: 18px;
}
/* register */
.register-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.register-form .heading,
.register-form > :last-child {
  grid-column: 1 / span 2;
}
/* management */
#main .grid-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
}

#main .sidebar-container {
  height: 100vh;
  position: sticky;
  top: 0;
  background: #fff;
  /* color: #ddd; */
}
.heading-items {
  font-size: 20px;
  text-align: center;
}

#sidebar-container .item-group li a {
  display: block;
  padding: 0.5rem;
  background: #fcad0c;

  margin: 0.3rem;
}
#sidebar-container .item-group li a:hover {
  background: #29293a;
  color: #fff;
}
/* info nav */
#info-nav .heading-items {
  background: #29293a;
  color: #ccc;
}
#info-nav .item-group {
  display: grid !important;
  grid-template-columns: 1fr;
}

/* Login page */

/* verification page */

.section.verify .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.code-input {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.code-input input {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}
/* footer */

#main-footer {
  /* background: var(--light-color);*/
  color: var(--color-primary);
  background: #29293a;
  color: #fff;
}
#main-footer a {
  color: #ccc;
}

#main-footer img {
  width: 80px;
}

#main-footer .footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}
#main-footer .footer-container > *:last-child {
  background: var(--background-color);
  color: #29293a;
  grid-column: 1 / span 4;
  padding: 8px;
  text-align: center;
  font-size: 16px;
}
#main-footer .footer-container input[type="email"] {
  width: 90%;
  padding: 8px;
  margin-bottom: 8px;
}

#main-footer .footer-container input[type="submit"] {
  width: 90%;
}
.list li {
  padding: 8px 0;
  border-bottom: var(--color-primary) dotted 1px;
}

.list li a:hover {
  color: var(--color-primary) !important;
}
#main-footer .btn:hover {
  background: var(--light-color);
  color: var(--color-secondary);
}
@media (max-width: 903px) {
  .callback-form {
    width: 70%;
  }
  .form .container {
    height: 100vh;
  }
}

@media (max-width: 600px) {
  .callback-form {
    width: 80%;
  }
  .register-form {
    grid-template-columns: 1fr;
    overflow-y: scroll;
    height: 70vh;
  }
  .register-form .heading,
  .register-form > :last-child {
    grid-column: 1;
  }
}

.contact-tofi {
  display: flex;
}

/* footer */

/* footer */

#main-footer {
  background: #29293a;
  color: #f4f4f4;
}

#main-footer img {
  width: 60px;
}

#main-footer .footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

#main-footer .footer-container > *:last-child {
  background: var(--background-color);
  grid-column: 1 / span 4;
  padding: 8px;
  text-align: center;
  font-size: 16px;
}

#main-footer .footer-container input[type="email"] {
  width: 90%;
  padding: 8px;
  margin-bottom: 8px;
}

#main-footer .footer-container input[type="submit"] {
  width: 90%;
}

#main-footer .footer-logo p {
  text-align: justify;
}

#main-footer .card {
  grid-template-rows: 0.1fr 0.1fr;
  grid-template-columns: 1fr;
  gap: 0;
  padding: 0;
  border: none;
}

.list li {
  padding: 8px 0;
  border-bottom: var(--primary-color-1) dotted 1px;
}

.list li a:hover {
  opacity: 0.7;
}
